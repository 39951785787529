export const supportNumber = '1 800.380.6800'
export const websiteNumber = '1 800.380.6800'

export const terms = {
	ctas: {
		phone: 'Call Now',
		form: 'Schedule Online',
		coupon: 'Up to $100 Off'
	}
}
